import { stringToUint8Array, arrayBufToB64 } from "./_utils";

/**
 * Transforms the credential request option JSON from the server into something the AuthN API can work with.
 */
const transformCredentialRequestOptions = (
    jsonFromServer: string,
): PublicKeyCredentialRequestOptions => {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return */
    const fromServer = JSON.parse(jsonFromServer);
    const allowCredentials = fromServer.allowCredentials
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        .map((credentialDescriptor: any) => {
            return {
                ...credentialDescriptor,
                id: stringToUint8Array(credentialDescriptor.id),
            };
        });
    const credRequestOptions: PublicKeyCredentialRequestOptions = {
        ...fromServer,
        challenge: stringToUint8Array(fromServer.challenge),
        allowCredentials: allowCredentials,
    };
    /* eslint-enable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return */
    return credRequestOptions;
};

/**
 * Encodes the binary data in the assertion into strings for posting to the server.
 */
const transformAssertionForServer = (newAssertion: PublicKeyCredential) => {
    const response = newAssertion.response as AuthenticatorAssertionResponse;
    return {
        id: newAssertion.id,
        rawId: arrayBufToB64(newAssertion.rawId),
        response: {
            authenticatorData: arrayBufToB64(response.authenticatorData),
            clientDataJSON: arrayBufToB64(response.clientDataJSON),
            signature: arrayBufToB64(response.signature),
        },
        type: newAssertion.type,
        authenticatorAttachment: newAssertion.authenticatorAttachment,
        assertionClientExtensions: newAssertion.getClientExtensionResults(),
    };
};

/**
 * Sign a challenge and post the data back to the server
 */
const verifyToken = async (formElem: HTMLFormElement) => {
    // Transform input data
    const optionsJSON = formElem.dataset.options;
    if (!optionsJSON) {
        throw new Error("Missing data-options");
    }
    const getCredOptions = transformCredentialRequestOptions(optionsJSON);
    // Sign the challenge
    const creds = (await navigator.credentials.get({
        publicKey: getCredOptions,
    })) as PublicKeyCredential;
    // Serialize the credentials and insert them into the form
    const credsForServer = transformAssertionForServer(creds);
    const input =
        formElem.querySelector<HTMLInputElement>("#id_assertion_data");
    if (!input) {
        throw new Error("Missing #id_assertion_data");
    }
    input.value = JSON.stringify(credsForServer);
    // Submit the form
    formElem.submit();
};

/**
 * Wait for the user to press the form button, then start the verification process.
 */
const startVerification = async () => {
    const _formElem = document.querySelector<HTMLFormElement>(
        "#webauthn-verify-token",
    );
    if (!_formElem) {
        return;
    }
    // Progress to next step
    await verifyToken(_formElem);
    document
        .querySelector<HTMLElement>(".waiting-for-user-action")!
        .classList.add("waiting-for-user-action--hidden");
    document
        .querySelector<HTMLElement>(".waiting-for-key")!
        .classList.remove("waiting-for-key--hidden");
};

document
    .querySelector<HTMLElement>(".waiting-for-user-action button")
    ?.addEventListener("click", () => {
        startVerification().catch(console.error);
    });
